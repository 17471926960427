
.spinner {
	animation: rotate 2s linear infinite;
	z-index: 2;
	position: absolute;
	right: .5rem;
	width: 1rem;
	height: 1rem;
	top:calc(50% - 0.5rem);

	& .path {
		stroke: hsl(210, 30, 70);
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -124;
	}
}



.whatsapp-block .whatsapp-content {
	transition: height 250ms cubic-bezier(.75, .78, 1, 1), margin-top 250ms cubic-bezier(.75, .78, 1, 1);
}

.whatsapp-block.is-closed .whatsapp-content .whatsapp-inner {
	opacity: 0;
	transition: opacity 100ms cubic-bezier(.75, .78, 1, 1);
	transition-delay: 90ms;
}

.whatsapp-block.is-closed .whatsapp-content .whatsapp-inner > * {
	opacity: 0;
}

.whatsapp-block.is-open .whatsapp-content {
	height: 264px;
}

.whatsapp-block.is-open .whatsapp-content .whatsapp-inner * {
	opacity: 1;
	transition: opacity 100ms cubic-bezier(.75, .78, 1, 1);
	transition-delay: 150ms;
}